<!-- 任务详情列表 -->
<template>
  <page-container
      ref="pageContainer"
      class="mission-detail-list-container"
      :scroll-top.sync="scrollTop"
  >
    <div class="mission-detail-item-title">任务要求：</div>
    <div class="mission-description" v-html="missionData.explains"></div>
    <div class="mission-detail-item-title">任务内容：</div>
    <div class="mission-detail-list">
      <div
          class="mission-detail"
          v-for="mission in missionDetailList"
          @click="missionDetail(mission)"
      >
        <div class="img-container">
          <van-image
              fit="cover"
              :src="mission.coverImg"
          />
        </div>
        <div class="mission-title">{{ mission.title }}</div>
      </div>
    </div>
  </page-container>
</template>

<script>
import { mapState } from 'vuex';
// import { getMissionDetailListDataTestApi as getMissionDetailInfo} from '@/test/missionDetailList';
import { getMissionDetailInfo } from '@/api/mission';

export default {
  name: 'missionDetailList',
  data() {
    return {
      scrollTop: 0,

      missionData: {},
    };
  },
  computed: {
    ...mapState({
      storeRefreshing: state => state.missionDetailList.refreshing,
    }),
    missionDetailList() {
      let { contentInfoList: list } = this.missionData || {};
      return Array.isArray(list) ? list : [];
    },
  },
  created() {
    this.$watch('storeRefreshing', () => {
      if (!this.storeRefreshing)
        return;
      this.$store.commit('missionDetailList/setRefreshing', false);

      this.missionData = {};

      let { taskId, title } = this.$route.query || {};
      document.title = title || '任务内容';

      getMissionDetailInfo({ taskId }).then(res => {
        this.missionData = res || {};
      });

    }, { immediate: true });
  },
  activated() {
    let { title } = this.$route.query || {};
    document.title = title || '任务内容';
    this.$refs.pageContainer.scrollToTop(this.scrollTop);
  },
  methods: {
    missionDetail(mission) {
      let { taskId, userTaskId, title } = this.$route.query || {};

      this.$router.push({
        path: '/missionDetail',
        query: { taskId, contentId: mission.contentId, userTaskId, title },
      });
    },
  }
};
</script>

<style lang="less" scoped>
.mission-detail-list-container {
  .mission-detail-item-title {
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 48px;
    font-size: 17px;
    font-weight: bolder;
    color: #333;
    background-color: #fff;
  }

  .mission-description {
    margin: 0 0 16px 0;
    padding: 18px 16px;
    background-color: #fff;

    /deep/ img {
      max-width: 100%;
      height: auto;
    }

    /deep/ video {
      width: 100%;
    }
  }

  .mission-detail-list {
    padding: 8px 0 16px 0;
    background-color: #fff;

    .mission-detail {
      margin: 0 16px;
      padding: 8px;
      border: 1px solid @primary-color;
      border-radius: 8px;

      & + .mission-detail {
        margin-top: 14px;
      }

      &:after {
        display: block;
        clear: both;
        content: "";
        visibility: hidden;
        height: 0
      }

      .img-container {
        float: left;
        position: relative;
        padding-top: 15%;
        width: 30%;
        border-radius: 4px;
        overflow: hidden;

        .van-image {
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      .mission-title {
        margin-left: 30%;
        padding: 0 0 0 10px;
        text-align: justify;
        word-break: break-all;
        font-size: 14px;
        line-height: 1.2;
      }
    }
  }
}
</style>
